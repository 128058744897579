
export default {
  name: 'SectionFeatures',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    inlineStyles() {
      let styles = {}
      if (this.data.backgroundImage) {
        styles = {
          ...{
            'background-image': `url(${this.resourceUrl(this.data.backgroundImage)})`
          }
        }
      }
      return styles
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
